.sort-by-widget {
  .link-list-wrapper ul li a.active {
    background-color: var(--bs-primary);

    button,
    button:hover,
    button:active,
    button:focus {
      span {
        color: var(--bs-primary-text);
      }
    }
  }
}
